<template>
  <export-excel
    class="export_btn"
    :title="title"
    :fields="custom_fields"
    :fetch="getData"
    type="csv"
    :footer="footer"
    :name="`Enroll Nomination List(${month}_${year}_${admin.township.name}).xls`"
  >
    <v-icon color="white" left>mdi-chevron-right-circle-outline</v-icon>Export
  </export-excel>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    month: { type: Number },
    year: { type: Number },
    nominationList: {
      type: Array,
    },
  },
  data: () => ({
    custom_fields: {
    
      Name: "name",
      Age: {
        field: "dob",
        callback: (value) => {
          let currentDate = new Date();
          let birthDate = new Date(value);
          let age = currentDate.getFullYear() - birthDate.getFullYear();
          let month = currentDate.getMonth() - birthDate.getMonth();
          if (
            month < 0 ||
            (month === 0 && currentDate.getDate() < birthDate.getDate())
          ) {
            age--;
          }
          return age;
        },
      },
      Sex: {
        field: "sex",
        callback: (val) => {
          return val == 1 ? "M" : "F";
        },
      },
      // "Code":"code",
      // "Year":"year",
      "DR-TB CODE": {
        field: "drtb_code",
        callback: (value) => {
          return value;
        },
      },   
      // "Mono-Poloy/SG":  {
      //   field: "monopoly",
      //   callback: (val) => {
      //     return val == 2 ? "Yes" : val == 3 ? "SG" : "No";
      //   },
      // },
      "Treatment Started Date": "treatment_startdate",
      Remark: {
        field: "last_enrolls",
        callback: (val) => {
          if (val) {
            const months = [
              { value: 1, text: "Jan" },
              { value: 2, text: "Feb" },
              { value: 3, text: "Mar" },
              { value: 4, text: "Apr" },
              { value: 5, text: "May" },
              { value: 6, text: "June" },
              { value: 7, text: "July" },
              { value: 8, text: "Aug" },
              { value: 9, text: "Sept" },
              { value: 10, text: "Oct" },
              { value: 11, text: "Nov" },
              { value: 12, text: "Dec" },
              { value: null, text: " " },
            ];
            
            const monthNames = [];
            for (const eachEnroll of val) {
              const month = months.find(
                (m) => m.value === Number(eachEnroll.support_month)
              );
              monthNames.push(month.text);
            }
            return ` ${monthNames.join(" , ")} `;
          }
          return "";
        },
      },
    },
  }),
  computed: {
    ...mapState("Admin", ["admin"]),
    title() {
      const months = [
        { value: 1, text: "Jan" },
        { value: 2, text: "Feb" },
        { value: 3, text: "Mar" },
        { value: 4, text: "Apr" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "Aug" },
        { value: 9, text: "Sept" },
        { value: 10, text: "Oct" },
        { value: 11, text: "Nov" },
        { value: 12, text: "Dec" },
        { value: null, text: " " },
      ];
      const month = months.find((m) => m.value === Number(this.month));
      return [
        "The following DR-TB patient are enroll nominated to receive TA and nutrition support from (Community Based DR-TB Care Project) implemented by the Union in collaboration with National Tuberculosis Program.",
        `Nominated Month/Year:${month?.text}/${this.year}` +
          Array(150).fill(" ").join("") +
          `Township${this.admin?.township.name}`,
      ];
    },
    footer() {
      const addSpace = Array(70).fill(" ").join("");
      const signSpace = Array(80).fill(" ").join("");
      const nameSpace = Array(75).fill(" ").join("");
      const dsgSpace = Array(67).fill(" ").join("");
      return [
        [
          `Prepared By${addSpace}` +
            `Verified By${addSpace}` +
            `Acknowledged By${addSpace}`,
        ],
        [`Sign:${signSpace}` + `Sign:${signSpace}` + `Sign:${signSpace}`],
        [`Name: ${nameSpace}` + `Name:${nameSpace}` + `Name:${nameSpace}`],
        [
          `Designation:${dsgSpace}` +
            `Designation:${dsgSpace}` +
            `Designation:${dsgSpace}`,
        ],
      ];
    },
  },
  methods: {
    getData() {
      if (this.nominationList.length > 0) return this.nominationList;
    },
   
    incrementNo() {
      this.custom_fields.No += 1;
      console.log(this.custom_fields.No);
      return  this.custom_fields.No.text;
    },
  },
 
};
</script>

<style scoped>
.export_btn {
  background: #ff4081;
  border-radius: 3px;
  color: white !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 120px;
  padding: 5px 15px;
  margin-bottom: 1px;
  cursor: pointer;
  width: 100%;
  cursor: pointer;
}
</style>
