<template>
  <div>
    <back-btn />
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="10">
          <v-card elevation="0">
            <v-card-text>
              <div class="pb-6">
                <h2 class="heading-3 text-center font-weight-light grey--text">
                 Enroll Nomination List
                </h2>
              </div>
              <v-row>
                <v-col class="col--padding" offset-lg="2" lg="4">
                  <label>Month</label>
                  <v-select
                    class="mr-1"
                    :items="export_months"
                    v-model="month"
                    item-text="text"
                    :error-messages="handleFieldErrors($v.month)"
                    item-value="value"
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <v-col class="col--padding" lg="4">
                  <label>Year</label>
                  <v-select
                    class="mr-1"
                    :items="years"
                    v-model="year"
                    item-text="text"
                    item-value="value"
                    :error-messages="handleFieldErrors($v.year)"
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col class="col--padding" lg="1">
                  <div>
                    <v-btn
                      class="ml-4"
                      height="40"
                      color="purple accent-4"
                      @click.prevent="getNominationList"
                      outlined
                      rounded
                      dark
                    >
                      <v-icon left>mdi-file-table-box</v-icon>
                      view report
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="0" :loading="loading">
            <v-row>
              <v-col>
                <div v-if="nominationList.length != 0">
                  <!-- export btn -->
                  <div style="width: 15%">
                    <export-nomination-list
                      :nominationList="nominationList"
                      :month="month"
                      :year="year"
                    />
                  </div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Age</th>
                          <th class="text-left">Sex</th>
                          <th class="text-left">DR-TB Code</th>
                          <!-- <th class="text-left">Mono-Poly/SG</th> -->
                          <th class="text-left">Treatment Started Date</th>
                        
                          <th class="text-left">Remark</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in nominationList" :key="i">
                          <td>{{ ++i }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ calculateAge(item.dob) }}</td>
                          <td>{{ item.sex == 1 ? "M" : "F" }}</td>
                          <td>{{ item.code }} / {{item.year }}</td>
                          <!-- <td>{{ item.monopoly==2 ? "Yes" : item.monopoly==3 ? "SG" : "No" }}</td> -->
                          <td>{{ item.treatment_startdate }}</td>
                          <td>
                            {{ showRemark(item.last_enrolls) }}
                          </td>
                         
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { ValidationMixin } from "@/mixins/ValidationMixin";
import { mapState } from "vuex";
import { CalculateAge } from "@/mixins/CalculateAge";
import BackBtn from "@/components/navigation/BackBtn.vue";
import ExportNominationList from "@/components/report/ExportNominationList.vue";
export default {
  components: { BackBtn, ExportNominationList },
  mixins: [ValidationMixin, CalculateAge],
  data: () => ({
    month: null,
    year: null,
  }),
  validations: {
    month: {
      required,
    },
    year: {
      required,
    },
  },
  methods: {
    getNominationList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.dispatch("Report/getNominationList", {
          month: this.month,
          year: this.year,
        });
      }
    },
    showMonthName(currentMonth) {
      const month = this.export_months.find((m) => m.value === Number(currentMonth));
      return month?.text;
    },
    showRemark(lastEnroll) {
      if (lastEnroll) { 
        const monthNames = [];
        for (const eachEnroll of lastEnroll) {
          const monthName = this.showMonthName(eachEnroll.support_month);
          console.log(monthName);
          monthNames.push(monthName);
        }

       
        return ` ${monthNames.join(" , ")}`;
      }
      return "";
    },
    suppportMonth(support_month){
      if (support_month) { 
        
        const monthName = this.showMonthName(support_month);
       
        return monthName;
      }
      return "";
    },
  },
  computed: {
    ...mapState("InitialData", ["export_months", "years", "types"]),
    ...mapState("Report", ["nominationList"]),
    ...mapState("Loading", ["loading"]),
  },
};
</script>
